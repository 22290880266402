import React from "react";
import { createRoot } from 'react-dom/client';
import normalize from "normalize-object";
import PieChart from "../components/PieChart"

function initialize() {
  const targetDomNode = document.getElementById("pieChart");
  if (targetDomNode) {
    const { chartData } = normalize(window['gon'].data)
    createRoot(targetDomNode).render(
      <PieChart data={chartData} />
    )
  }
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
