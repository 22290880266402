import React from "react"
import PropTypes from "prop-types"
import Button from "./Button"

const NextButton = (props) => (
  <Button
    text={props.finish ? "Finish" : "Next"}
    className="nextFinishButton"
    onClick={props.onClick}
    disabled={props.disabled}
    visible={props.visible}
  />
)

NextButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  finish: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default NextButton
