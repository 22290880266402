function initialize() {
  const imageInput = document.getElementById('image-input');
  const submitButton = document.getElementById('submit-button');

  if (imageInput) {
    imageInput.addEventListener('change', (event) => {
      const imagePreviewContainer = document.getElementById('image-preview');
      const imagePreview = document.getElementById('image-preview-img');
      imagePreview.src = URL.createObjectURL(event.target.files[0]);
      imagePreview.onload = function() {
        URL.revokeObjectURL(imagePreview.src) // free memory
      }
      imagePreviewContainer.style.display = 'block';

      submitButton.disabled = false;
    });
  }

  const rejectUpload = document.getElementById('reject-upload');
  if (rejectUpload) {
    rejectUpload.addEventListener('click', () => {
      const imageInput = document.getElementById('image-input');
      imageInput.value = '';
      const imagePreviewContainer = document.getElementById('image-preview');
      imagePreviewContainer.style.display = 'none';
    });
  }

  const radioGroupsSelectors = [
    'input[name="word_list_picture[importance_score]"]',
    'input[name="word_list_picture[frustration_score]"]',
    'input[name="word_list_picture[use_score]"]',
    'input[name="word_list_picture[difficulty_score]"]'
  ];

  const validateRadioButtons = () => {
    if (submitButton) {
      const isAnyRadioSelected = radioGroupsSelectors.some(selector => {
        const radioButtons = document.querySelectorAll(selector);
        return Array.from(radioButtons).some(radio => radio.checked);
      });
      submitButton.disabled = !isAnyRadioSelected;
    }
  };

  radioGroupsSelectors.forEach(selector => {
    const radioButtons = document.querySelectorAll(selector);
    radioButtons.forEach(radio => {
      radio.addEventListener('change', validateRadioButtons);
    });
  });

  validateRadioButtons();

}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
