import visitEventsApi from "../api/visitEvents"
import emaEventsApi from "../api/emaEvents"
import * as bootstrap from 'bootstrap';
import moment from "moment"

const DEFAULT_EMA_LOCATION = "home";

const loggedIn = () => {
    return document.body.classList.contains("js--signed-in")
}

const updateLastActivityAt = () => {
    if (loggedIn()) {
        localStorage.setItem("lastActivityAt", Date.now())
    }
}

const updateJustLoggedIn = (setting) => {
    if (loggedIn()) {
        localStorage.setItem("justLoggedIn", setting);
    }
}

const checkTriggerEmaModal = (previouslyJustLoggedIn, queryParams) => {
    const deckType = document.getElementById("deck_type");
    let triggerEmaCheck = false;
    let emaLocation = DEFAULT_EMA_LOCATION;
    let locationId = 0;



    if (location.pathname === '/participant' && (previouslyJustLoggedIn || queryParams.get('ema_question_id'))) {
        triggerEmaCheck = true
    }
    else if (location.pathname.match(/\/participant\/deck_assignments\/\d+\/congratulations/)) { // pathname is of format /participant/deck_assignment/{id}/congratulations strictly
        triggerEmaCheck = true
        locationId = document.getElementById("location_id").value;
        if (deckType) {
            emaLocation = deckType.value
        }
    }
    return [triggerEmaCheck, emaLocation, locationId];
}

const callEmaController = (emaLocation, locationId, queryParams) => {
    const emaQuestionId = queryParams.get('ema_question_id');
    emaEventsApi.modal(emaLocation, location.pathname, locationId, emaQuestionId).then(({ data, status }) => {
        if (status !== 200 || data.modal === "") {
            return;
        }
        const modalContainer = document.getElementById("js--modalDiv")
        modalContainer.classList.add("d-flex", "justify-content-center", "flex-column");
        modalContainer.innerHTML = data.modal
        let myModal = new bootstrap.Modal(modalContainer, {
            backdrop: 'static',
            keyboard: false
        })
        document.dispatchEvent(new CustomEvent("modal.load", { bubbles: true }));
        myModal.show();
    })
}

const callVisitController = () => {
    visitEventsApi.create().then(({ data, status }) => {
        if (status !== 201) {
            return
        }
        const modalContainer = document.getElementById("js--modalDiv")
        modalContainer.innerHTML = data.modal
        let myModal = new bootstrap.Modal(modalContainer, {
            backdrop: 'static',
            keyboard: false
        })

        myModal.show()

    })
}

const checkForModals = () => {
    const lastActivityAtInMs = localStorage.getItem("lastActivityAt") || 0
    const lastActivityAt = moment(parseInt(lastActivityAtInMs))
    const justLoggedIn = lastActivityAtInMs === 0;
    const previouslyJustLoggedIn = localStorage.getItem("justLoggedIn") === "true";
    const triggerVisitModal = lastActivityAtInMs === 0 || moment().diff(lastActivityAt, 'minutes') >= 150
    const queryParams = new URLSearchParams(window.location.search);
    let [triggerEmaModal, emaLocation, locationId] = checkTriggerEmaModal(previouslyJustLoggedIn, queryParams);

    if (justLoggedIn) {
        updateJustLoggedIn(true);
    }

    if (loggedIn()) {
        updateLastActivityAt();
        if (triggerVisitModal && (process.env.RAILS_ENV !== 'test' || location.pathname === '/participant')) {
            callVisitController();
        }
        else if (triggerEmaModal) {
            callEmaController(emaLocation, locationId, queryParams);
            updateJustLoggedIn(false);
        }

    } else {
        // Do not track last activity at if we're not logged in
        localStorage.removeItem("lastActivityAt")
        localStorage.removeItem("justLoggedIn")
    }
}

// Check if we need to display the visit modal after page has been loaded.
document.addEventListener("DOMContentLoaded", checkForModals);
document.addEventListener("turbo:render", checkForModals);
document.addEventListener("click", (event) => {
    const closingVisitModal = document.querySelector(".trackVisitModal");
    if (closingVisitModal && event.target.hasAttribute("data-bs-dismiss")) {
        checkForModals();
    }
});