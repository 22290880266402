import React, {useState, useRef, useEffect, useCallback, forwardRef} from 'react';

const MultiVideoScriptPlayer = ({ videoData, updateWatchedCount, setCurrentIndex }) => {
  const videoObject = videoData.video;
  const noOfAvatars = videoObject.noOfAvatars;
  const avatars = [...new Set(videoObject.cardVideoClips.map(clip => clip.speakerType))];
  
  const videoPlayerRefs = useRef([...Array(noOfAvatars)].map(() => React.createRef()));
  const currentVideoClipIndex = useRef(0);

  const handleOnEnded = useCallback((index) => {
    currentVideoClipIndex.current = currentVideoClipIndex.current + 1;

    if (currentVideoClipIndex.current < videoObject.cardVideoClips.length) {
      for(let i = currentVideoClipIndex.current; i < videoObject.cardVideoClips.length; i++) {
        if (videoObject.cardVideoClips[i].speakerType === avatars[index]) {
          videoPlayerRefs.current[index].current.src = videoObject.cardVideoClips[i].filePath;
          break;
        }
      }

      const nextAiVideoClip = videoObject.cardVideoClips[currentVideoClipIndex.current];
      const nextPlayerIndex = avatars.indexOf(nextAiVideoClip.speakerType);
      setCurrentIndex(currentVideoClipIndex.current);
      videoPlayerRefs.current[nextPlayerIndex].current.play();
    }

    if(currentVideoClipIndex.current === videoObject.cardVideoClips.length - 1) {
      updateWatchedCount(1);
    }
  }, [videoData]);

  const initializeVideoPlayers = useCallback(() => {
    videoPlayerRefs.current.forEach((playerRef, index) => {
      for(const clip of videoObject.cardVideoClips) {
        if (clip.speakerType === avatars[index]) {
          playerRef.current.src = clip.filePath;
          break;
        }
      }
    });
  }, [videoData]);

  const handlePlayPause = useCallback(() => {
    if (currentVideoClipIndex.current === videoObject.cardVideoClips.length) {
      currentVideoClipIndex.current = 0;
      initializeVideoPlayers();

      const currentAiVideoClip = videoObject.cardVideoClips[currentVideoClipIndex.current];
      const playerIndex = avatars.indexOf(currentAiVideoClip.speakerType);
      videoPlayerRefs.current[playerIndex].current.src = currentAiVideoClip.filePath;
      videoPlayerRefs.current[playerIndex].current.play();
      return;
    }

    const currentAiVideoClip = videoObject.cardVideoClips[currentVideoClipIndex.current];
    const playerIndex = avatars.indexOf(currentAiVideoClip.speakerType);
    const isPlaying = videoPlayerRefs.current[playerIndex].current.paused;

    if (isPlaying) {
      videoPlayerRefs.current[playerIndex].current.play();
    } else {
      videoPlayerRefs.current[playerIndex].current.pause();
    }

  }, [videoData]);

  const handleNext = useCallback(() => {
    const currentClip = videoObject.cardVideoClips[currentVideoClipIndex.current];
    const currentPlayerIndex = avatars.indexOf(currentClip.speakerType);
    const currentPlayer = videoPlayerRefs.current[currentPlayerIndex].current;

    if (!currentPlayer.paused) {
      currentPlayer.pause();
    }

    currentVideoClipIndex.current = currentVideoClipIndex.current + 1;

    if (currentVideoClipIndex.current < videoObject.cardVideoClips.length) {
      for(let i = currentVideoClipIndex.current; i < videoObject.cardVideoClips.length; i++) {
        if (videoObject.cardVideoClips[i].speakerType === avatars[currentPlayerIndex]) {
          videoPlayerRefs.current[currentPlayerIndex].current.src = videoObject.cardVideoClips[i].filePath;
          break;
        }
      }

      const nextAiVideoClip = videoObject.cardVideoClips[currentVideoClipIndex.current];
      const nextPlayerIndex = avatars.indexOf(nextAiVideoClip.speakerType);
      setCurrentIndex(currentVideoClipIndex.current);
      videoPlayerRefs.current[nextPlayerIndex].current.play();
    }
  }, [videoData]);

  const handlePrevious = useCallback(() => {
    const currentClip = videoObject.cardVideoClips[currentVideoClipIndex.current];
    const currentPlayerIndex = avatars.indexOf(currentClip.speakerType);
    const currentPlayer = videoPlayerRefs.current[currentPlayerIndex].current;

    if (!currentPlayer.paused) {
      currentPlayer.pause();
      currentPlayer.currentTime = 0;
    }

    if (currentVideoClipIndex.current > 0) {
      currentVideoClipIndex.current = currentVideoClipIndex.current - 1;
      const previousAiVideoClip = videoObject.cardVideoClips[currentVideoClipIndex.current];
      const previousPlayerIndex = avatars.indexOf(previousAiVideoClip.speakerType);

      for(let i = currentVideoClipIndex.current; i >= 0; i--) {
        if (videoObject.cardVideoClips[i].speakerType === avatars[previousPlayerIndex]) {
          videoPlayerRefs.current[previousPlayerIndex].current.src = videoObject.cardVideoClips[i].filePath;
          break;
        }
      }
      setCurrentIndex(currentVideoClipIndex.current);
      videoPlayerRefs.current[previousPlayerIndex].current.play();
    }
  }, [videoData]);

  useEffect(() => {
    currentVideoClipIndex.current = 0;
    setCurrentIndex(currentVideoClipIndex.current);
    initializeVideoPlayers();
  }, [videoData]);


  return (
    <div className="video-dialogue-container" key={videoData.id}>
      <div className="row">
        {[...Array(noOfAvatars)].map((_, index) => (
          <div className="col" key={index}>
            <video ref={videoPlayerRefs.current[index]} controls={false} onEnded={() => handleOnEnded(index)} style={{width: '100%'}}>
              <source type="video/mp4"/>
            </video>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12 text-center p-3">
          {noOfAvatars > 1 &&
            <button className="btn btn-outline-secondary mx-3" onClick={handlePrevious}>Previous</button>
          }
          <button className="btn btn-outline-primary mx-3" onClick={handlePlayPause}>Play / Pause</button>
          {noOfAvatars > 1 &&
            <button className="btn btn-outline-secondary mx-3" onClick={handleNext}>Next</button>
          }
        </div>
      </div>
    </div>
  )
};

export default MultiVideoScriptPlayer;
