import React, { useState } from "react"
import PropTypes from "prop-types"
import CardVideoPlayer from "./CardVideoPlayer"
import AudioRecording from "./AudioRecording"
import NextButton from "./NextButton"
import scriptTrainingCardResponseApi from "../api/scriptTrainingCardResponses"
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css"

const SingleVideoScriptTrainingExercise = ({ card, isLastCard, moveToNextCard, deckPlayThroughId, cardResponse }) => {
  const [displayNextButton, setDisplayNextButton] = useState(false)

  const nextClicked = async () => {
    await scriptTrainingCardResponseApi.complete(cardResponse.id, deckPlayThroughId)
    moveToNextCard()
    if (!isLastCard) {
      setDisplayNextButton(false)
    }
  }

  const updateWatchedCount = (watchCount) => {
    scriptTrainingCardResponseApi.update(cardResponse.id, deckPlayThroughId, watchCount)
  }

  return (
    <div className="scriptTrainingExercises d-md-flex justify-content-center">
      <div className="scriptTrainingCard">
        <CardVideoPlayer url={card.videoUrl} poster={card.posterUrl} onWatchCountUpdate={updateWatchedCount} />
        <AudioRecording
          cardResponseId={cardResponse.id}
          onRecording={() => setDisplayNextButton(false) }
          onRecordingCompleted={() => setDisplayNextButton(true)}
        />
        <NextButton visible={displayNextButton} finish={isLastCard} onClick={nextClicked} />
      </div>
      <div className="scriptTrainingScript">
        <SimpleBar forceVisible="n" autoHide={false} style={{ maxHeight: "100%" }} >
          <div className="scriptContent" dangerouslySetInnerHTML={{__html: card.script}} />
        </SimpleBar>
      </div>
    </div>
  )
}

SingleVideoScriptTrainingExercise.propTypes = {
  card: PropTypes.object.isRequired,
  isLastCard: PropTypes.bool.isRequired,
  moveToNextCard: PropTypes.func.isRequired,
  deckPlayThroughId: PropTypes.number.isRequired,
  cardResponse: PropTypes.object.isRequired,
};

export default SingleVideoScriptTrainingExercise;
