import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import NextButton from "./NextButton"
import { updateCardResponse, completeCardResponse } from "../api/wordToPictureCardResponses"

const getSampleCards = (cards, currentCard) => {
  const filteredCards = cards.filter(card => card.id !== currentCard.id)
  const sample = _.sampleSize(filteredCards, 3)
  sample.splice(0, 0, currentCard)
  return _.shuffle(sample)
}

const WordToPictureExercise = ({ card, allCards, moveToNextCard, isLastCard, deckPlayThroughId, cardResponse }) => {
  // If you leave and come back, you'll start from the same image/card, but the other three incorrect cards may change.
  const [sampleCards, setSampleCards] = useState(getSampleCards(allCards, card))
  const [optionSelected, setOptionSelected] = useState(false)
  const [displayNextButton, setDisplayNextButton] = useState(false)

  // If you choose an image and see the correct answer. If you leave and come back, you'll remain on the same card.
  // We don't advance the stored current card until you click next.
  const handleCardClicked = (selectedCard) => {
    if(!optionSelected) {
      setOptionSelected(true)
      updateCardResponse(cardResponse.id, deckPlayThroughId, selectedCard.id == card.id).then(() => {
        setDisplayNextButton(true)
      })
    }
  }

  const nextClicked = () => {
    completeCardResponse(cardResponse.id, deckPlayThroughId).then(() => {
      moveToNextCard()
      if (!isLastCard) {
        setOptionSelected(false)
        setDisplayNextButton(false)
      }
    })
  }

  useEffect(() => {
    setSampleCards(getSampleCards(allCards, card))
  }, [card]);

  return (
    <div className="row w2pExercises">
      <div className="col-12 px-0">
        <h1 className="text-center">{card.title}</h1>
        <div className="w2pExerciseCards">
          {sampleCards.map(sampleCard =>
            <div key={sampleCard.id} className="w2pExerciseCard border" data-bs-title={sampleCard.title} onClick={() => handleCardClicked(sampleCard)}>
              <img
                src={sampleCard.imageUrl}
                className={optionSelected ? (sampleCard.id === card.id ? "green-border" : "red-border") : ""}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-12 px-0">
        <NextButton visible={displayNextButton} finish={isLastCard} onClick={nextClicked} />
      </div>
    </div>
  )
}

WordToPictureExercise.propTypes = {
  card: PropTypes.object.isRequired,
  allCards: PropTypes.array.isRequired,
  isLastCard: PropTypes.bool.isRequired,
  moveToNextCard: PropTypes.func.isRequired,
  deckPlayThroughId: PropTypes.number.isRequired,
  cardResponse: PropTypes.object.isRequired,
};

export default WordToPictureExercise;
