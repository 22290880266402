import React from "react";
import SimpleCalendar from "../components/SimpleCalendar"
import {createRoot} from "react-dom/client";

function initialize() {
  const targetDomNode = document.getElementById("simpleCalendar");
  if (targetDomNode) {
    const { events } = window['gon'].data
    createRoot(targetDomNode).render(
      <SimpleCalendar events={events} />
    )
  }
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
