import * as bootstrap from "bootstrap";

function initialize() {
    const modals = document.querySelectorAll('.image-preview-modal');
    modals.forEach(modal => {
        modal.addEventListener('click', (e) => {
            bootstrap.Modal.getInstance(modal).hide();
            if (e.target === modal) {
                bootstrap.Modal.getInstance(modal).hide();
            }
        });
    });
}

document.addEventListener('DOMContentLoaded', initialize);
document.addEventListener("turbo:render", initialize)
