import React, { useRef } from "react"
import PropTypes from "prop-types"
import {
  Player,
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';
import videoViewApi from "../api/instructionalVideoViews"

const InstructionalVideoPlayer = ({ poster, url, instructionalVideoAssignmentId }) => {
  let lastSecondRecorded = 0
  let videoCompleted = false
  let instructionalVideoViewId = null
  const player = useRef()

  const onPlay = async (event) => {
    const currentTime = event.target.currentTime
    // When clicking 'play' and 'InstructionalVideoView' does not exist, creates it and then records the event,
    // otherwise only records the event.
    if (instructionalVideoViewId) {
      videoViewApi.createEvent(instructionalVideoViewId, "play", currentTime)
    } else {
      const { data } = await videoViewApi.create(instructionalVideoAssignmentId)
      instructionalVideoViewId = data.id
      videoViewApi.createEvent(instructionalVideoViewId, "play", currentTime)
    }
  }

  const onPause = (event) => {
    videoViewApi.createEvent(instructionalVideoViewId, "pause", event.target.currentTime)
  }

  const onSeeked = (event) => {
    videoViewApi.createEvent(instructionalVideoViewId, "seek", event.target.currentTime)
  }

  const onEnded = (event) => {
    videoViewApi.update(instructionalVideoViewId, event.target.duration)
  }

  const onTimeUpdate = (event) => {
    const second = Math.trunc(event.target.currentTime)

    // Avoid sending a request every second, send it after 5 or more seconds have passed
    if (second >= lastSecondRecorded + 5) {
      lastSecondRecorded = second
      videoViewApi.update(instructionalVideoViewId, lastSecondRecorded)
    }

    // Updated completed_at when 80% of the video is reached
    if ((event.target.duration * 0.8 <= second) && !videoCompleted) {
      videoCompleted = true
      videoViewApi.complete(instructionalVideoViewId)
    }
  }

  return (
    // This appears to support the same events as the <video> element, so we can
    // include callbacks like `onTimeUpdate` or `onPlay`
    // https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Media_events
    <Player ref={player} poster={poster} src={url} onPlay={onPlay} onPause={onPause} onSeeked={onSeeked} onTimeUpdate={onTimeUpdate} onEnded={onEnded}>
      <BigPlayButton position="center" />
      <ControlBar>
        <CurrentTimeDisplay order={4.1} />
        <TimeDivider order={4.2} />
        <VolumeMenuButton disabled />
      </ControlBar>
    </Player>
  )
}

InstructionalVideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  instructionalVideoAssignmentId: PropTypes.string.isRequired,
  poster: PropTypes.string,
};

export default InstructionalVideoPlayer
