import axios from "axios"

export const createCardResponse = (cardId, deckPlayThroughId, responseType) => {
  return axios.post(`/deck_play_throughs/${deckPlayThroughId}/card_responses`, {
    card_response: {
      card_id: cardId,
      type: responseType,
    }
  })
}
