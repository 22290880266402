import React from "react"
import PropTypes from "prop-types"
import { join } from "lodash"
import earBlueSvg from '../images/ear-blue.svg';
import earWhiteSvg from '../images/ear-white.svg';

const Button = (props) => {
  const {
    variant = "primary",
    text,
    size = null,
    onClick,
    disabled,
    block = true,
    className = "",
    padding = "py-3",
    iconPrefix = "fa",
    icon,
    visible = true,
    earIconBlue = false,
    earIconWhite = false,
  } = props
  const classes = ["btn", `btn-${variant}`, size && `btn-${size}`, block && "btn-block", className, padding]

  if (!visible) return null;

  return(
    <button
      onClick={onClick}
      disabled={disabled}
      className={join(classes, " ")}
    >
      {icon && <i className={`${iconPrefix} fa-${icon} me-2`} /> }
      {earIconBlue && <img src={earBlueSvg} className="earIcon" />}
      {earIconWhite && <img src={earWhiteSvg} className="earIcon" />}
      {text}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  iconPrefix: PropTypes.string,
  icon: PropTypes.string,
  padding: PropTypes.string,
  visible: PropTypes.bool,
  earIconBlue: PropTypes.bool,
  earIconWhite: PropTypes.bool,
}

export default Button
