import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import WrittenResponse from "./WrittenResponse"
import AudioRecording from "./AudioRecording"
import Hints from "./Hints"
import Button from "./Button"
import NextButton from "./NextButton"
import pictureCardResponseApi from "../api/pictureCardResponses"
import earBlueSvg from '../images/ear-blue.svg';

let audioUrl, audioContext

const PictureExercise = ({ card, isLastCard, moveToNextCard, deckPlayThroughId, cardResponse }) => {
  const [writtenResponseData, setWrittenResponseData ] = useState(null)
  const [displayWrittenResponse, setDisplayWrittenResponse] = useState(false)
  const [displayAnswer, setDisplayAnswer] = useState(false)
  const [recordingAudio, setRecordingAudio] = useState(false)

  const onWrittenCompleted = (imagData) => {
    setDisplayWrittenResponse(false)
    setWrittenResponseData(imagData)
  }

  const onRecordingCompleted = () => {
    setRecordingAudio(false)
  }

  const nextClicked = () => {
    pictureCardResponseApi.complete(cardResponse.id, deckPlayThroughId).then(() => {
      moveToNextCard()
      if(audioContext.state === "running") {
        audioContext.close()
      }
      if (!isLastCard) {
        setWrittenResponseData(null)
        setDisplayAnswer(false)
      }
    })
  }

  const updateHintCheckCount = (paramName, count) => {
    pictureCardResponseApi.updateHintCount(cardResponse.id, deckPlayThroughId, paramName, count)
  }

  const hearAnswer = async () => {
    audioContext = new AudioContext()
    const audioBuffer = audioContext.createBufferSource()
    const response = await fetch(audioUrl, {cache: "no-cache"})
    const audioData = await response.arrayBuffer()
    const buffer = await audioContext.decodeAudioData(audioData)
    const gainNode = audioContext.createGain()

    gainNode.connect(audioContext.destination)
    gainNode.gain.value = 1

    audioBuffer.buffer = buffer
    audioBuffer.connect(gainNode)

    audioBuffer.onended = () => {
      audioContext.close()
    }
    audioBuffer.start()
  }

  const correctAnswerClicked = () => {
    pictureCardResponseApi.markAnswerChecked(cardResponse.id, deckPlayThroughId)
    hearAnswer()
    setDisplayAnswer(true)
  }

  useEffect(() => {
    audioUrl = card.answerAudioUrl
  }, [card])

  return (
    <div className="row pictureExercises">
      <div className="col-6 pictureExerciseWrapper mx-auto">
        <div className="imageWrapper">
          {displayAnswer && (
            <h1 className="text-center mb-1" onClick={hearAnswer}>
              {card.title}
              <img src={earBlueSvg} className="titleIcon" />
            </h1>
          )}
          <img className="pictureImage mb-1 border" src={card.imageUrl} />
        </div>
        {displayWrittenResponse &&
          <WrittenResponse cardResponseId={cardResponse.id} onCompleted={onWrittenCompleted} onCancel={() => setDisplayWrittenResponse(false)} />
        }
        <div className={`buttonsWrapper ${displayWrittenResponse ? "d-none" : ""}`}>
          <AudioRecording
            cardResponseId={cardResponse.id}
            onRecording={() => setRecordingAudio(true) }
            onRecordingCompleted={onRecordingCompleted}
          />
          <Button
            text="Write Response"
            icon="pen"
            className="mt-3"
            onClick={() => setDisplayWrittenResponse(true) }
            disabled={recordingAudio}
            visible={!writtenResponseData}
          />
          {writtenResponseData &&
            <div className="writtenResponseWrapper border mt-2 p-1" onClick={() => setDisplayWrittenResponse(true) }>
              <Button variant="link" icon="pencil-alt" text="Write Again" size="md" className="" padding="ps-2 py-1" block={false} />
              <img src={writtenResponseData} />
            </div>
          }
          <Button
            text="Correct Answer"
            onClick={correctAnswerClicked}
            disabled={recordingAudio}
            className="mt-3"
            visible={!displayAnswer}
          />
        </div>
        <div className="nextButtonContainer">
          <NextButton
            visible={displayAnswer}
            disabled={recordingAudio}
            finish={isLastCard}
            onClick={nextClicked}
          />
        </div>
      </div>
      <div className="col-6 col-lg-4 mx-auto">
        <Hints card={card} disabled={recordingAudio || displayWrittenResponse} onHintCheckCountUpdate={updateHintCheckCount} />
      </div>
    </div>
  )
}

PictureExercise.propTypes = {
  card: PropTypes.object.isRequired,
  isLastCard: PropTypes.bool.isRequired,
  moveToNextCard: PropTypes.func.isRequired,
  deckPlayThroughId: PropTypes.number.isRequired,
  cardResponse: PropTypes.object.isRequired,
};

export default PictureExercise;
