import React from "react";
import { createRoot } from 'react-dom/client';
import normalize from "normalize-object";
import CardsManager from "../components/CardsManager"
import PronunciationExercise from "../components/PronunciationExercise"

function initialize() {
  const targetDomNode = document.getElementById("pronunciation-exercise-container");
  if (targetDomNode) {
    const props = normalize(window['gon'].data)
    createRoot(targetDomNode).render(
      <CardsManager {...props} component={PronunciationExercise} progressBar={true} />
    )
  }
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
