import React from "react"
import PropTypes from "prop-types"

const ProgressBar = ({ currentCard, totalCards }) => {
  const percentage = ((currentCard/totalCards) * 100).toFixed(0)

  return(
    <div className="row d-flex justify-content-center">
      <div className="col-11 col-lg-8">
        <span className="small">Progress: {percentage}%</span>
        <div className="progress" style={{height: "20px"}}>
          <div className="progress-bar" role="progressbar" style={{width: `${percentage}%`}} />
        </div>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  totalCards: PropTypes.number.isRequired,
  currentCard: PropTypes.number.isRequired,
}

export default ProgressBar
