import React from "react";
import { createRoot } from 'react-dom/client';
import InstructionalVideoPlayer from "../components/InstructionalVideoPlayer"

function initialize() {
  Array.from(document.getElementsByClassName("js--videoComponent")).forEach(element => {
    const props = Object.assign({}, element.dataset)
    createRoot(element).render(
      <InstructionalVideoPlayer {...props} />
    )
  })
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
