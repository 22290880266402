import React, { useRef } from "react"
import PropTypes from "prop-types"
import audioResourceHearingsApi from "../api/audioResourceHearings";

const AudioResourcePlayer = ({ url, audioResourceAssignmentId }) => {
  let lastSecondRecorded = 0;
  let audioCompleted = false
  let audioResourceHearingId = null;
  const player = useRef()

  const onPlay = async (event) => {
    const currentTime = event.target.currentTime
    // When clicking 'play' and 'AudioResourceHearing' does not exist, creates it and then records the event,
    // otherwise only records the event.
    if (audioResourceHearingId) {
      audioResourceHearingsApi.createEvent(audioResourceHearingId, "play", currentTime)
    } else {
      const { data } = await audioResourceHearingsApi.create(audioResourceAssignmentId)
      audioResourceHearingId = data.id
      audioResourceHearingsApi.createEvent(audioResourceHearingId, "play", currentTime)
    }
  }

  const onPause = (event) => {
    audioResourceHearingsApi.createEvent(audioResourceHearingId, "pause", event.target.currentTime)
  }

  const onSeeked = (event) => {
    audioResourceHearingsApi.createEvent(audioResourceHearingId, "seek", event.target.currentTime)
  }

  const onEnded = (event) => {
    audioResourceHearingsApi.update(audioResourceHearingId, event.target.duration)
    audioResourceHearingsApi.complete(audioResourceHearingId)
  }

  const onTimeUpdate = (event) => {
    const second = Math.trunc(event.target.currentTime)

    // Avoid sending a request every second, send it after 5 or more seconds have passed
    if (second >= lastSecondRecorded + 5) {
      lastSecondRecorded = second
      audioResourceHearingsApi.update(audioResourceHearingId, lastSecondRecorded)
    }

    // Updated completed_at when 80% of the audio is reached
    if ((event.target.duration * 0.8 <= second) && !audioCompleted) {
      audioCompleted = true
      audioResourceHearingsApi.complete(audioResourceHearingId)
    }
  }

  return (
    <div>
      <audio
        ref={player}
        src={url}
        controls
        onPlay={onPlay}
        onPause={onPause}
        onSeeked={onSeeked}
        onTimeUpdate={onTimeUpdate}
        onEnded={onEnded}
      ></audio>
    </div>
  );
};

AudioResourcePlayer.propTypes = {
  url: PropTypes.string.isRequired,
  audioResourceAssignmentId: PropTypes.string.isRequired,
};

export default AudioResourcePlayer
