// This file is a variant of the example from the repo: https://github.com/SableRaf/PencilToP5
import p5 from "p5"
import Pressure from "pressure"
import { OneEuroFilter } from "./oneEuroFilter"

// SETTINGS
// How sensitive is the brush size to the pressure of the pen?
const pressureMultiplier = 0 // 10
// What is the smallest size for the brush?
const minBrushSize = 4 // 1
// Higher numbers give a smoother stroke
const brushDensity = 5
// Jitter smoothing parameters
const minCutoff = 0.0001 // decrease this to get rid of slow speed jitter but increase lag (must be > 0)
const beta      = 1.0  // increase this to get rid of high speed lag
const brushColor = "#000"

// GLOBALS
let xFilter, yFilter, pFilter
let inBetween
let prevPenX = 0
let prevPenY = 0
let prevBrushSize = 1
let amt, x, y, s, d
let pressure = -2
let drawCanvas
let isPressureInit = false
let isDrawing = false
let isDrawingJustStarted = false

const resetGlobalValues = () => {
  xFilter, yFilter, pFilter, inBetween, amt, x, y, s, d = null
  prevPenX = 0
  prevPenY = 0
  prevBrushSize = 1
  pressure = -2
  drawCanvas = null
  isPressureInit = false
  isDrawing = false
  isDrawingJustStarted = false
}

const buildSketch = (container) => {
  const initializeCanvas = (p) => {
    const { width, height } = container.getBoundingClientRect()
    drawCanvas = p.createCanvas(width, height)
    drawCanvas.background("#FFF")
    drawCanvas.position(container.getBoundingClientRect().x)
  }

  return (p) => {
    p.setup = () => {
      resetGlobalValues()
      // Filters used to smooth position and pressure jitter
      xFilter = new OneEuroFilter(60, minCutoff, beta, 1.0)
      yFilter = new OneEuroFilter(60, minCutoff, beta, 1.0)
      pFilter = new OneEuroFilter(60, minCutoff, beta, 1.0)

      initializeCanvas(p)
    }

    p.draw = () => {
      // Start Pressure.js if it hasn't started already
      if (!isPressureInit) initPressure(container)

      if (isDrawing) {
        // Smooth out the position of the pointer
        let penX = xFilter.filter(p.mouseX, p.millis())
        let penY = yFilter.filter(p.mouseY, p.millis())

        // What to do on the first frame of the stroke
        if(isDrawingJustStarted) {
          prevPenX = penX
          prevPenY = penY
        }

        // Smooth out the pressure
        pressure = pFilter.filter(pressure, p.millis())

        // Define the current brush size based on the pressure
        let brushSize = minBrushSize + (pressure * pressureMultiplier)

        // Calculate the distance between previous and current position
        d = p.dist(prevPenX, prevPenY, penX, penY)

        // The bigger the distance the more ellipses
        // will be drawn to fill in the empty space
        inBetween = (d / p.min(brushSize,prevBrushSize)) * brushDensity

        // Add ellipses to fill in the space
        // between samples of the pen position
        for (let i=1; i <= inBetween; i++) {
          amt = i / inBetween
          s = p.lerp(prevBrushSize, brushSize, amt)
          x = p.lerp(prevPenX, penX, amt)
          y = p.lerp(prevPenY, penY, amt)
          p.noStroke()
          p.fill(brushColor)
          p.ellipse(x, y, s)
        }

        // Draw an ellipse at the latest position
        p.noStroke()
        p.fill(brushColor)
        p.ellipse(penX, penY, brushSize)

        // Save the latest brush values for next frame
        prevBrushSize = brushSize
        prevPenX = penX
        prevPenY = penY

        isDrawingJustStarted = false
      }
    }

    p.windowResized = () => {
      resetGlobalValues()
      initializeCanvas(p)
    }
  }
}

// Disabling scrolling and bouncing on iOS Safari
const preventDefault = (e) => {
  e.preventDefault()
}

const disableScroll = () => {
  document.body.addEventListener('touchmove', preventDefault, { passive: false })
}

const enableScroll = () => {
  document.body.removeEventListener('touchmove', preventDefault, { passive: false })
}

const initPressure = (container) => {
  Pressure.set(container, {
    start: (_event) => {
      // this is called on force start
      isDrawing = true
      isDrawingJustStarted = true
      // prevent scrolling on iOS Safari
      disableScroll()
    },
    end: () => {
      // this is called on force end
      isDrawing = false
      pressure = 0
      enableScroll()
    },
    change: (force, _event) => {
      if (!isPressureInit) isPressureInit = true
      pressure = force
    }
  });

  Pressure.config({
    polyfill: true, // use time-based fallback ?
    polyfillSpeedUp: 0, // how long does the fallback take to reach full pressure
    polyfillSpeedDown: 0,
    preventSelect: true,
    only: null
  })
}

export const initSketch = (element) => {
  return new p5(buildSketch(element), element)
}
