import axios from "axios"

const modal = (emaLocation, requestPath, locationId, emaQuestionId) => {
    return axios.post("/ema_responses/modal", {
        ema_location: emaLocation,
        request_path: requestPath,
        location_id: locationId,
        ema_question_id: emaQuestionId
    });
}

export default {
    modal,
}