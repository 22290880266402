import React from "react"
import PropTypes from "prop-types"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

const Calendar = ({ events, timestamp, cacheVersion }) => {

  function eventDataTransform(eventData) {
    // Delete the url if it's null or inactive. Passing a `null` will cause an
    // href of the string "null" in the calendar.
    if (!eventData.url || !eventData.isActive) {
      delete eventData.url
    }

    if (eventData.isActive) {
      eventData.classNames = ["active"]
    }
    return {
      ...eventData,
      cacheKey: `${eventData.id}-${timestamp}-${cacheVersion}`
    };
  }

  // Event is the FullCalendar event, jsEvent is the JS click event
  function eventClick({jsEvent, event}) {
    jsEvent.preventDefault()
    if (event.url && confirm("Would you like to join the meeting?")) {
      window.open(event.url)
    }
  }

  return (
    <FullCalendar
      key={`calendar-${timestamp}-${cacheVersion}`}
      plugins={[dayGridPlugin, timeGridPlugin, bootstrap5Plugin, interactionPlugin]}
      events={events}
      themeSystem="bootstrap5"
      headerToolbar={{
        center: 'dayGridMonth,timeGridWeek,timeGridDay'
      }}
      height="100%"
      scrollTime="09:00"
      fixedWeekCount={false}
      eventClick={eventClick}
      eventDataTransform={eventDataTransform}
    />
  )
}

Calendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
}

export default Calendar
