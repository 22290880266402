import axios from "axios"

const create = (videoAssigmentId) => {
  return axios.post("/instructional_video_views",
    { instructional_video_view: { instructional_video_assignment_id: videoAssigmentId } }
  )
}

const createEvent = (videoViewId, eventType, videoTime) => {
  axios.post(`/instructional_video_views/${videoViewId}/instructional_video_events`,
    { instructional_video_event: { event: eventType, video_time: videoTime } }
  )
}

const update = (videoViewId, furthestSecond) => {
  axios.put(`/instructional_video_views/${videoViewId}`,
    { instructional_video_view: { furthest_second: furthestSecond } }
  )
}

const complete = (videoViewId) => {
  axios.post(`/instructional_video_views/${videoViewId}/complete`)
}

export default {
  create,
  update,
  complete,
  createEvent,
}
