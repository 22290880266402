import React from "react"
import PropTypes from "prop-types"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import bootstrap5Plugin from "@fullcalendar/bootstrap5"

const SimpleCalendar = ({ events }) => {
  return (
    <FullCalendar
      plugins={[dayGridPlugin, bootstrap5Plugin]}
      events={events}
      themeSystem="bootstrap5"
      headerToolbar={{
        left: "",
        center: "title",
      }}
      fixedWeekCount={false}
      height="100%"
    />
  )
}

SimpleCalendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
}

export default SimpleCalendar
