import React from "react";
import normalize from "normalize-object";
import CardsManager from "../components/CardsManager"
import WordToPictureExercise from "../components/WordToPictureExercise"
import {createRoot} from "react-dom/client";

function initialize() {
  const targetDomNode = document.getElementById("w2p-exercise-container");
  if (targetDomNode) {
    const props = normalize(window['gon'].data)
    createRoot(targetDomNode).render(
      <CardsManager {...props} component={WordToPictureExercise} progressBar={true} />
    )
  }
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
