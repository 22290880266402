function setup() {
    document.querySelectorAll('.btn-outline').forEach(button => {
        button.addEventListener('touchstart', function (event) {
            event.preventDefault();
            this.classList.add('btn-outline-focus');
        });
        button.addEventListener('touchend', function (event) {
            this.classList.remove('btn-outline-focus');
            addHiddenFieldAndSubmit(event, button);
        });

        button.addEventListener('touchcancel', function () {
            this.classList.remove('btn-outline-focus');
        });
    });

    document.querySelectorAll('.btn-ema-star').forEach(button => {
        button.addEventListener('touchstart', function (event) {
            if (event.cancelable) event.preventDefault();
            const ratingElements = document.querySelectorAll('.rating .col label');
            ratingElements.forEach((label) => {
                label.style.color = '#aaa'; // Reset color
            });

            const form = event.target.closest('form');

            const labels = getLabelsForRange(form, 6 - parseInt(button.value));
            labels.forEach((label) => {
                label.style.color = 'orange';
            });

        });
        button.addEventListener('touchend', function (event) {
            addHiddenFieldAndSubmit(event, button);
        });

        button.addEventListener('touchcancel', function () {
            this.classList.remove('btn-outline-focus');
        });
    });
}

function addHiddenFieldAndSubmit(event, button) {
    const form = event.target.closest('form');
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'commit';
    hiddenField.value = button.value;
    form.appendChild(hiddenField);
    form.submit();
}

function getLabelsForRange(form, x) {
    const labels = form.querySelectorAll('label');
    const selectedLabels = Array.from(labels).filter(label => {
        const forAttribute = label.getAttribute('for');
        return /^e\d+$/.test(forAttribute) && parseInt(forAttribute.slice(1)) >= x;
    });
    return selectedLabels;
}

document.addEventListener("modal.load", setup);