import React from "react"
import PropTypes from "prop-types"
import HintItem from "./HintItem"

const Hints = ({ card, disabled, onHintCheckCountUpdate }) => {
  return(
    <div className={`hints-component p-3`}>
      <h2>Get Hints</h2>
      {card.hints.map((hint, index) =>
          <HintItem key={index} hint={hint} disabled={disabled} onHintCheckCountUpdate={onHintCheckCountUpdate} />
      )}
    </div>
  )
}

Hints.propTypes = {
  card: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default Hints
