import axios from "axios"

const update = (id, deckPlayThroughId, videoWatchedCount) => {
  return axios.put(`/deck_play_throughs/${deckPlayThroughId}/script_training_card_responses/${id}`, {
    script_training_card_response: {
      video_watched_count: videoWatchedCount
    }
  })
}

const complete = (id, deckPlayThroughId) => {
  return axios.post(`/deck_play_throughs/${deckPlayThroughId}/script_training_card_responses/${id}/complete`)
}

export default {
  update,
  complete,
}
