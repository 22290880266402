import React from "react"

export default function LoadingSpinner() {
  return (
    <div className="container">
      <div className="col-12 text-center d-flex flex-column justify-content-center exerciseContainer">
        <i className="fa fa-spinner fa-pulse d-block fa-2x mb-3" />
        <span>Loading...</span>
      </div>
    </div>
  )
}
