import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import {goToCard, markCompleted} from "../api/deckPlayThroughs"
import {createCardResponse} from "../api/cardResponses"
import LoadingSpinner from "./LoadingSpinner"
import ProgressBar from "./ProgressBar"
import SingleVideoScriptTrainingExercise from "../components/SingleVideoScriptTrainingExercise"
import MultiVideoScriptTrainingExercise from "../components/MultiVideoScriptTrainingExercise"
import PronunciationExercise from "../components/PronunciationExercise"
import PictureExercise from "../components/PictureExercise"
import WordToPictureExercise from "./WordToPictureExercise";

const CardsManager = ({id, cards, cardResponseType, currentCard, deckAssignmentId, progressBar}) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(currentCard)
  const [cardResponse, setCardResponse] = useState(null)
  const nextIndex = currentCardIndex + 1

  const moveToNextCard = async () => {
    if (nextIndex < cards.length) {
      await goToCard(id, nextIndex)
      const response = await createCardResponse(cards[nextIndex].id, id, cardResponseType)
      setCardResponse(response.data)
      setCurrentCardIndex(nextIndex)
    } else {
      await markCompleted(id)
      window.location.href = `/participant/deck_assignments/${deckAssignmentId}/congratulations`
    }
  }

  const card = cards.length > 0 ? cards[currentCardIndex] : null;

  useEffect(() => {
    if (cards.length > 0) {
      createCardResponse(cards[currentCardIndex].id, id, cardResponseType).then(({data}) => {
        setCardResponse(data)
      })
    }
  }, [])

  const renderExercise = () => {
    if (card.videoType === 'single_card_video' && card.exerciseType === 'script_training') {
      return (
        <>
          <SingleVideoScriptTrainingExercise
            card={cards[currentCardIndex]}
            allCards={cards}
            isLastCard={nextIndex >= cards.length}
            moveToNextCard={moveToNextCard}
            deckPlayThroughId={id}
            cardResponse={cardResponse}
          />
          {progressBar &&
            <ProgressBar totalCards={cards.length} currentCard={currentCardIndex}/>
          }
        </>
      );
    } else if (card.videoType === 'multi_card_video' && card.exerciseType === 'script_training') {
      return (
        <>
          <MultiVideoScriptTrainingExercise
            card={cards[currentCardIndex]}
            allCards={cards}
            isLastCard={nextIndex >= cards.length}
            moveToNextCard={moveToNextCard}
            deckPlayThroughId={id}
            cardResponse={cardResponse}
          />
          {progressBar &&
            <ProgressBar totalCards={cards.length} currentCard={currentCardIndex}/>
          }
        </>
      );
    } else if (card.videoType === 'single_card_video' && card.exerciseType === 'pronunciation') {
      return (
        <>
          <PronunciationExercise
            card={cards[currentCardIndex]}
            isLastCard={nextIndex >= cards.length}
            moveToNextCard={moveToNextCard}
            deckPlayThroughId={id}
            cardResponse={cardResponse}
          />
          {progressBar &&
            <ProgressBar totalCards={cards.length} currentCard={currentCardIndex}/>
          }
        </>
      );
    } else if (card.exerciseType === 'picture_card') {
      return (
        <>
          <PictureExercise
            card={cards[currentCardIndex]}
            isLastCard={nextIndex >= cards.length}
            moveToNextCard={moveToNextCard}
            deckPlayThroughId={id}
            cardResponse={cardResponse}
          />
          {progressBar &&
            <ProgressBar totalCards={cards.length} currentCard={currentCardIndex}/>
          }
        </>
      );
    } else if (card.exerciseType === 'word_to_picture_card') {
      return (
        <>
          <WordToPictureExercise
            card={cards[currentCardIndex]}
            allCards={cards}
            isLastCard={nextIndex >= cards.length}
            moveToNextCard={moveToNextCard}
            deckPlayThroughId={id}
            cardResponse={cardResponse}
          />
          {progressBar &&
            <ProgressBar totalCards={cards.length} currentCard={currentCardIndex}/>
          }
        </>
      );
    }
  }

  if (cards.length === 0) {
    return <div className="alert alert-warning">
      There are no cards present in this picture deck. If this a mistake, please contact your SLP.
    </div>
  } else {

    return cardResponse ? renderExercise() : <LoadingSpinner/>;
  }
}

CardsManager.propTypes = {
  id: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  cardResponseType: PropTypes.string.isRequired,
}

export default CardsManager
