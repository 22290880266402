import React from "react"
import PropTypes from "prop-types"
import { PieChart } from 'react-chartkick'
import 'chartkick/chart.js'

const PieChartComponent = ({ data }) => {
  return (
    <PieChart
      data={data}
      donut={true}
      legend="right"
      library={{ tooltips: { enabled: false } }}
      colors={["#FFE3AA", "#8CA336", "#862D63", "#728DA5", "#C986AF", "#2A4E6E", "#F9B225"]}
    />
  )
}

PieChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
}

export default PieChartComponent
