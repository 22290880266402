import moment from "moment"

function initialize() {
  Array.from(document.getElementsByClassName("js--localTime")).forEach(element => {
    const format = element.dataset.format || "LLL"
    const datetime = element.getAttribute("datetime")
    element.innerHTML = moment(datetime).format(format)
  })
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
