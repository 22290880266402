import axios from "axios"

const create = (audioAssigmentId) => {
  return axios.post("/audio_resource_hearings",
    { audio_resource_hearing: { audio_resource_assignment_id: audioAssigmentId } }
  )
}

const createEvent = (audioResourceHearingId, eventType, audioPlayTime) => {
  axios.post(`/audio_resource_hearings/${audioResourceHearingId}/audio_resource_events`,
    { audio_resource_event: { event: eventType, audio_time: audioPlayTime } }
  )
}

const update = (audioResourceHearingId, furthestSecond) => {
  axios.put(`/audio_resource_hearings/${audioResourceHearingId}`,
    { audio_resource_hearing: { furthest_second: furthestSecond } }
  )
}

const complete = (audioResourceHearingId) => {
  axios.post(`/audio_resource_hearings/${audioResourceHearingId}/complete`)
}

export default {
  create,
  update,
  complete,
  createEvent,
}
