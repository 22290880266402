import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import AudioRecording from "./AudioRecording"
import NextButton from "./NextButton"
import scriptTrainingCardResponseApi from "../api/scriptTrainingCardResponses"
import "simplebar/dist/simplebar.min.css";
import MultiVideoScriptPlayer from "./MultiVideoScriptPlayer";

const MultiVideoScriptTrainingExercise = ({ card, isLastCard, moveToNextCard, deckPlayThroughId, cardResponse }) => {
  const { video } = card;
  const [displayNextButton, setDisplayNextButton] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(0)

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    const scrollToCurrent = () => {
      const currentElement = scrollableDivRef.current.querySelector('.highlight');
      if (currentElement) {
        currentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };
    scrollToCurrent();
  }, [currentIndex]);

  const nextClicked = async () => {
    await scriptTrainingCardResponseApi.complete(cardResponse.id, deckPlayThroughId)
    moveToNextCard()
    if (!isLastCard) {
      setDisplayNextButton(false)
    }
  }

  const updateWatchedCount = (watchCount) => {
    scriptTrainingCardResponseApi.update(cardResponse.id, deckPlayThroughId, watchCount)
  }

  return (
    <div>
      <div className="orientationLock">
        <div className="wrapper">
          <div className="alert alert-info">
            <p className="text-center">
              Please rotate your device to portrait mode for the best experience.
            </p>
          </div>
        </div>
      </div>
      <div className="scriptTrainingExercises">
        <div>
          <MultiVideoScriptPlayer videoData={card} updateWatchedCount={updateWatchedCount} setCurrentIndex={setCurrentIndex}/>
          <AudioRecording
            cardResponseId={cardResponse.id}
            onRecording={() => setDisplayNextButton(false)}
            onRecordingCompleted={() => setDisplayNextButton(true)}
          />
          <NextButton visible={displayNextButton} finish={isLastCard} onClick={nextClicked}/>
        </div>
        <div className="card mt-4">
          <div className="card-header">
            <h5 className="card-title">{video.title}</h5>
          </div>
          <div className="card-body scrollable-card-body"  ref={scrollableDivRef}>
            {video.cardVideoClips.map((clip, index) => (
              <p
                key={index}
                className={
                  index === currentIndex
                    ? 'highlight ' + (clip.speakerType === 'SLP' ? 'text-muted fst-italic' : 'fw-bold')
                    : (clip.speakerType === 'SLP' ? 'text-muted fst-italic' : 'fw-bold')
                }
              >
                {clip.speakerType === 'SLP' && (
                  <span className="badge bg-secondary me-2">SLP</span>
                )}
                {clip.humanizedScript}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

MultiVideoScriptTrainingExercise.propTypes = {
  card: PropTypes.object.isRequired,
  isLastCard: PropTypes.bool.isRequired,
  moveToNextCard: PropTypes.func.isRequired,
  deckPlayThroughId: PropTypes.number.isRequired,
  cardResponse: PropTypes.object.isRequired,
};

export default MultiVideoScriptTrainingExercise;
