import React from "react";
import { createRoot } from 'react-dom/client';
import AudioResourcePlayer from "../components/AudioResourcePlayer"


function initialize() {
  Array.from(document.getElementsByClassName("js--audioPlayerComponent")).forEach(element => {
    const props = Object.assign({}, element.dataset)
    createRoot(element).render(
      <AudioResourcePlayer {...props} />
    )
  })
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
