import React from "react";
import { createRoot } from 'react-dom/client';
import Calendar from "../components/Calendar"

function initialize() {
  const targetDomNode = document.getElementById("calendar");
  if (targetDomNode) {
    const { events, timestamp } = window['gon'].data;
    const cacheVersion = window['gon'].cacheVersion;
    createRoot(targetDomNode).render(
      <Calendar events={events} timestamp={timestamp} cacheVersion={cacheVersion} />
    )
  }
}

document.addEventListener("DOMContentLoaded", initialize);
document.addEventListener("turbo:render", initialize);
