import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Button from "./Button"

const HintItem = ({ hint, disabled, onHintCheckCountUpdate }) => {
  const { audioUrl, label, text, buttonText, checkedCountParamName } = hint
  const [visible, setVisible] = useState(false)
  const [checkedCount, setCheckedCount] = useState(0)

  const rePlayAudio = () => {
    updateCount()
    playAudio()
  }

  const playAudio = async () => {
    if (audioUrl) {
      const audioContext = new AudioContext()
      const audioBuffer = audioContext.createBufferSource()
      const response = await fetch(audioUrl, {cache: "no-cache"})
      const audioData = await response.arrayBuffer()
      const buffer = await audioContext.decodeAudioData(audioData)
      const gainNode = audioContext.createGain()

      gainNode.connect(audioContext.destination)
      gainNode.gain.value = 1

      audioBuffer.buffer = buffer
      audioBuffer.connect(gainNode)

      audioBuffer.onended = () => {
        audioContext.close()
      }
      audioBuffer.start()
    }
  }

  const onButtonClicked = () => {
    setVisible(true)
    updateCount()
    playAudio()
  }

  const updateCount = () => {
    const updatedCheckedCount = checkedCount + 1
    setCheckedCount(updatedCheckedCount)
    onHintCheckCountUpdate(checkedCountParamName, updatedCheckedCount)
  }

  useEffect(() => {
    setVisible(false)
    setCheckedCount(0)
  }, [text, audioUrl]);

  return (
    <div className="row mb-2 hint-item">
      {visible
        ?
          <>
            <div className="col-5 my-auto label font-weight-bold pe-0">{label}:</div>
            {text && <div className="col-7 my-auto definition ps-0">{text}</div>}
            {audioUrl &&
              <div className="col-7 my-auto ps-0">
                <Button
                  text="Listen Again"
                  onClick={rePlayAudio}
                  disabled={disabled}
                  variant="outline-primary"
                  padding="px-3"
                  size="md"
                  block={false}
                  earIconBlue={true}
                />
              </div>
            }
          </>
        :
          <div className="col-12">
            <Button
              text={buttonText}
              onClick={onButtonClicked}
              disabled={disabled}
              earIconWhite={!!audioUrl}
            />
          </div>
      }
    </div>
  )
}

HintItem.propTypes = {
  hint: PropTypes.shape({
    label: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    text: PropTypes.string,
    audioUrl: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  onHintCheckCountUpdate: PropTypes.func.isRequired,
}

export default HintItem
