import SimpleBar from "simplebar"

function initialize() {
  Array.from(document.getElementsByClassName("js--simpleBar")).forEach((el) => {
    new SimpleBar(el, { autoHide: false, forceVisible: true })
  })
}

document.addEventListener('DOMContentLoaded', initialize);
document.addEventListener("turbo:render", initialize)
