import axios from "axios"

export const updateCardResponse = (id, deckPlayThroughId, isCorrect) => {
  return axios.put(`/deck_play_throughs/${deckPlayThroughId}/word_to_picture_card_responses/${id}`, {
    word_to_picture_card_response: {
      correct: isCorrect
    }
  })
}

export const completeCardResponse = (id, deckPlayThroughId) => {
  return axios.post(`/deck_play_throughs/${deckPlayThroughId}/word_to_picture_card_responses/${id}/complete`)
}
