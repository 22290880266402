import axios from "axios"

const updateHintCount = (id, deckPlayThroughId, checkedCountParamName, hintCheckedCount) => {
  return axios.put(`/deck_play_throughs/${deckPlayThroughId}/picture_card_responses/${id}`, {
    picture_card_response: {
      [checkedCountParamName]: hintCheckedCount
    }
  })
}

const markAnswerChecked = (id, deckPlayThroughId) => {
  return axios.post(`/deck_play_throughs/${deckPlayThroughId}/picture_card_responses/${id}/mark_answer_checked`)
}

const complete = (id, deckPlayThroughId) => {
  return axios.post(`/deck_play_throughs/${deckPlayThroughId}/picture_card_responses/${id}/complete`)
}

export default {
  updateHintCount,
  markAnswerChecked,
  complete,
}
